.header{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 50px 20px 50px;
    position: sticky;
    top: 0px;
    background: #ffffff;
    z-index: 99;
}
.header a{
    text-decoration: none;
    color: #18191F;
}
.header a:hover{
   color:#1245A8;
}
.header a::after{
    color:red;
    text-decoration: underline;
}
.header li{
    cursor:pointer;
}
.logo{
    width: 80px;
}
.header_menu1{
    list-style: none;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    font-size: 16px;
    font-weight: 500;
}
.header_menu2{
    list-style: none;
    display: flex;
    align-items: center;
    gap: 40px;
    padding-left: 0px;
    font-size: 16px;
    font-weight: 500;
}
.header_login{
    color: #1245A8;
    cursor:pointer;
}
.header_logout{
    color: #1245A8;
    background:none;
    border:none;
    font-size: 16px;
    cursor:pointer;
}
.header_button{
    padding: 10px 20px;
    background: #1245A8;
    border: none;
    border-radius: 6px;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
}
.mobile_nav_menu_button{
    background: none;
    border: none;
    font-size: 25px;
    display: none;
}
.mobile_nav_drawer{

}
.mobile_nav_header{
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 15px;
    margin-right: 10px;
    border-bottom: 1px solid #EFEFEF;
}
.drawer_logo{
    width: 40px;
}
.mobile_nav_links{
    display: flex;
    flex-direction: column;
    list-style: none;
    align-items: flex-start;
}
.mobile_nav_links > li{
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 17px;
}
.mobile_nav_links .expandable{
    max-height: 0px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
}
.mobile_nav_links .expanded {
    max-height: 500px;
    transition: max-height 0.4s ease-in;
}
.ReactModal__Overlay{
    background: rgb(45, 45, 45, 0.82) !important;
}
.login-popup{
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 4px;
    outline: none;
    height: 50%;
    width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.login-popup-active{
    display: flex;
}
.login-popup-left{
    display: flex;
    justify-content: space-between;
    width: 50%;
}
.login-popup-left-bg{
    width: 50%;
    position: absolute;
}
.login-popup-left-section{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
}
.login-popup-left-text{
    width: 60%;
}
.login-popup-left-logo{
    width: 13%;
}
.login-popup-right-section{
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: center;
    width: 50%;
}
.login-popup-right-text1{
    font-size: 32px;
    color: #18191F;
    margin: 20px 0px;
}
.login-popup-right-text2{
    color: #333333;
    margin: 5px 0px 0px 0px;
    font-size: 20px;
}
.login-popup-right-text3{
    margin: 5px;
    font-size: 14px;
    color: #333333;
    font-weight: 400;
}
.login-popup-right-input{
    width: 90%;
    padding: 10px;
    border: 2px solid #E0E0E0;
    border-radius: 4px;
    margin-top: 10px;
}
.login-popup-right-button{
    background: #1245A8;
    margin-top: 15px;
    border: none;
    width: 98%;
    padding: 10px;
    border-radius: 4px;
    color: #ffffff;
}
.login-popup-right-text4{
    color: #616C7D;
    font-size: 12px;
    background: #D1E0F7;
    border-radius: 4px;
    padding: 5px;
}
.login-popup-right-text5{
    margin: 0px;
}

.mobile-nav-links-logged-in-container {
    text-align: left;
    cursor: pointer;
}

.mobile-nav-links-logged-in-list {
    list-style: none;
    padding-left: 15px;
}

.profile-container {
    display: flex;
    align-items: center;
}

.profile-icon {
    height: 25px;
    width: 25px;
    margin-left: 10px;
    margin-bottom: 6px;
}

@media (max-width:961px)  {
    .header_menu1{
        display: none;
    }
    .header_button{
        display: none;
    }
    .mobile_nav_menu_button{
        display: inline-block;
    }
    .header{
        padding: 15px 10px 15px 10px;
        justify-content: space-between;
    }
    .mobile_nav_links .expandable a{
        color: #18191F;
        text-decoration: none;
    }
    .mobile_nav_links .expandable{
        text-align: left;
        margin: 5px 0px 5px 25px;
        gap: 7px;
    }
}