.fractional-cfo-section {
    margin-top: 10px !important;
}
.bookkeeping_container{
    display: flex;
    background-color: #6C89B1;
    margin: 50px 125px;
    padding: 220px 75px;
    align-items: center;
}
.bookkeeping_section{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: absolute;
    column-gap: 30px;
    row-gap: 30px;
    transform: translateX(70%);
    margin-top: 170px;
}
.bookkeeping_point{
    width: 250px;
    background: #E1E9F5;
    /* transform: translateX(80%); */
    margin: 0px;
    color: #717171;
    padding: 35px 30px;
    font-size: 20px;
    text-align: justify;
}
.bookkeeping_title{
    font-size: 50px;
    color:#4D4D4D;
}
.bookkeeping_desc{
    font-size: 22px;
    padding: 0px 240px;
    line-height: 2em;
}
.bookkeeping_question{
    font-size: 48px;
    width: 30%;
    text-align: left;
    color: #ffffff;
}
@media (max-width:961px)  {
    .bookkeeping_title{
        font-size:24px;
    }
    .bookkeeping_desc{
        font-size: 20px;
        line-height: 32px;
        padding: 0px 35px;
    }
    .bookkeeping_container{
        margin: 0px;
        padding: 20px;
        flex-direction: column;
        align-items: center;
    }
    .bookkeeping_question{
        font-size: 24px;
        padding: 0 10px;
        width: 100%;
        text-align: center;
    }
    .bookkeeping_section{
        position: static;
        width: 100%;
        margin: 5px 20px 20px 20px;
        transform: translateX(0%);
        font-size: 16px;
        padding: 20px;
        text-align: left;
        line-height: 24px;
        grid-template-columns: repeat(1, 1fr);
    }
    .bookkeeping_point{
        width:fit-content;
    }
}