.profile-section{
    border: 1px solid #E1E9F5;
    border-radius: 8px;
    padding: 10px 30px;
    width: 85%;
    text-align: left;
}
.profile-input-section{
    display: flex;
    gap: 30px;
    margin-bottom: 10px;
}
.profile-input{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative
}
.profile-input h5{
    margin: 10px 0px 5px 0px;
}
.profile-input input{
    border: 1px solid #E1E9F5;
    border-radius: 8px;
    padding: 10px;
    min-width: 250px;
}
.address input{
    min-width:300px ;
}
.profile-update{
    background: #1245A8;
    border: none;
    border-radius: 8px;
    color: #ffffff;
    margin-top: 15px;
    padding: 11px 70px;
    min-width: 270px;
}

.profile-update:disabled{
    background: lightblue;
    border: none;
    border-radius: 8px;
    color: #ffffff;
    margin-top: 15px;
    padding: 11px 70px;
    min-width: 270px;
}

.input-validation-error {
    color: maroon;
    font-size: 11px;
    position: absolute;
    bottom: -16px;
}