
.accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 
  .accordion-item {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
  }
  
 
  .accordion-header {
    background-color: #E1E9F5;
    padding: 15px 30px;
    cursor: pointer;
    position: relative;
    font-size: 24px;
    color: #717171;
    display: flex;
    border-radius: 2px;
    align-items: center;
    justify-content: space-between;
  }
  
  
  .accordion-content {
    padding: 15px 30px;
    background: #F1F0F0;
    display: flex;
    justify-content: center;
    margin: 0px 10px;
    font-size: 24px;
    color: #717171;
  }
  
 
  .accordion-header::after {
    content: '\25bc'; 
    position: absolute;
    top: 50%;
    right: 10px; 
    transform: translateY(-50%) rotate(0deg);
    transition: transform 0.3s ease; 
  }
  

  .accordion-header.active::after {
    content: '\25b2'; 
    transform: translateY(-50%) rotate(-180deg);
  }

  .accordion-title{
    font-size: 50px;
    text-align: left;
    width: 75%;
    display: flex;
    justify-content: center;
    margin: 150px 0px 35px 0px;
  }

  @media (max-width:961px)  {
    .accordion-title{
      width: fit-content;
      font-size: 24px;
      margin: 20px 55px;
      text-align: center;
    }
  }
  
  