.profile-page{
    display: flex;
    gap: 50px;
    padding: 20px 50px;
}
.left-options{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: baseline;
}
.right-content{
    padding: 30px;
    border: 1px solid #E1E9F5;
    border-radius: 16px;
    width: 80%;
    display: flex;
}