.remove-plan-icon{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.plan-card{
    display: flex;
    align-items: flex-start;

}

.cart-plan-container{
    flex: 1;
    display: flex;
    box-shadow: 0px 0px 11.4px 0px #00000040;
}

.plan-card{
    flex: 1;
}

.plan-body{
    flex: 1 ;
}

.highlight {
    color: var(--secondary-text-color)
}

.plan-heading-text{
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
}

.separator-container {
    padding: 8px 24px;
}

.list-separator {
    width: 100%;
    color: var(--text-grey-color);
}

.plan-price-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 10px;
}

.price-label-text {
    color: var(--text-grey-color);
}

.price-value-text {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin-left: 20px
}

.bill-line-key {
    color: var(--text-grey-color);
}

.bill-line-value {
    color: var(--text-grey-color);
}

.discount {
    color: var(--text-color) !important
}

.price-subtext {
    color: var(--secondary-text-color)
}


