.footer_section1{
    padding: 70px;
}
.footer_title{
    font-size: 50px;
    font-weight: 600;
    color: #191A15;
}
.footer_input_section{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}
.footer_input{
    /* width: 31%; */
    border-radius: 4px 0px 0px 4px;
    padding: 17px 150px;
}
.footer_button{
    background: #1245A8;
    color: #ffffff;
    font-weight: 500;
    border-radius: 0px 4px 4px 0px;
    font-size: 16px;
    padding: 17px 35px;
    border: none;
}
.footer_bottom{
    display: flex;
    justify-content: space-between;
    background: #161C28;
    padding: 75px 200px;
    color: #ffffff;
}
.footer_bottom_section1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.footer_logo{
    width: 60%;
}
.footer_contact{
    text-align: left;
    font-size: 16px;
    font-weight: 500;
}
.footer_menus{
    display: flex;
    gap: 100px;
}
.footer_menu_section{
    display: flex;
    flex-direction: column;
    align-items: baseline;
}
.footer_menu_title{
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 5px;
}
.footer_menu_section ul{
    list-style: none;
    padding: 0px;
    text-align: left;
}
.footer_menu_items{
    padding-bottom: 10px;
}
.footer_menu_items a{
    color: #ffffff;
    text-decoration: none;
}
@media (max-width:961px)  {
    .footer_bottom{
        flex-direction: column;
        padding: 10px 35px;
        gap: 25px;
    }
    .footer_bottom_section1{
        flex-direction: row;
        gap: 15px;
        align-items: center;
    }
    .footer_logo{
        width:30%;
    }
    .footer_menus{
        gap: 75px;
        justify-content: center;
    }
    .footer_section1{
        padding: 30px 20px;
    font-weight: 700;
    background: #E1E9F5;
    }
    .footer_title{
        font-size: 36px;
    }
    .footer_input_section{
        padding-top: 20px;
    }
    .footer_input{
        padding: 12px 20px;
    }
    .footer_button{
        padding: 13px 19px;
        font-size:14px;
    }
}