.business_tax_container {
    display: flex;
    background-color: #6C89B1;
    margin: 120px 125px 170px 125px;
    padding: 270px 75px;
    align-items: center;
}

.businesstax-point {
    width: 320px;
    background: #E1E9F5;
    margin: 0px;
    color: #717171;
    padding: 0px 30px 30px 30px;
    font-size: 20px;
    text-align: justify;
}

.businesstax-footer-container {
    margin: 120px 0px 30px 0px;
}

@media (max-width:961px)  {
    .business_tax_container {
        margin: 0px;
        padding: 20px;
        flex-direction: column;
        align-items: center;
    }

    .businesstax-point{
        width:fit-content;
    }
}

