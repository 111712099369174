.counter-container {
    display: flex;
    align-items: center;
}

.value-container {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    width: 30px;
    margin: 0 5px;
    background: var(--secondary-bgcolor);
    border-radius: 2px;
    color: var(--text-color)
}

.button-img {
    display: flex;
    cursor: pointer;
}


