.other_service_title{
    color: #4D4D4D;
    margin: 20px;
    font-size: 50px;
}
.other_service_desc{
    font-size: 20px;
    color: #717171;
    padding: 0px 260px;
    margin-top: 0px;
}
.container {
    display: flex;
    background-color: #6C89B1;
    margin: 50px 125px;
    padding: 160px 75px;
    align-items: center;
}

.other_service_question {
    color: #ffffff;
    font-size: 40px;
    width: 20%;
    text-align: left;
}

.other_service_answer {
    position: absolute;
    width: 40%;
    background: #E1E9F5;
    transform: translateX(80%);
    margin: 0px;
    color: #717171;
    padding: 65px 30px;
    font-size: 20px;
    text-align: justify;
}
.other_service_title2{
    color: #4D4D4D;
    font-size: 50px;
    padding: 0 20%;
}
.other_service_desc2{
    position: relative;
    margin-top: 50px;
}
.other_service_desc2 p{
    background: #E1E9F5;
    color: #717171;
    font-size: 24px;
    padding: 49px 275px 50px 120px;
    text-align: left;
}
.other_service_desc2 img{
    width: 20%;
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.other_services_section{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
    padding: 50px 150px;
}
.other_services_section_options{
    background: #E1E9F5;
    border-radius: 6px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.other_services_section_top{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.other_services_section_icon{
    width:16%;
}
.other_services_section_title{
    font-weight: 500;
    color: #282C2F;
    font-size: 24px;
    text-align: left;
}
.other_services_section_desc{
    font-size: 20px;
    color: #3D3F40;
    text-align: left;
}
@media (max-width:961px)  {
    .other_service_question{
        font-size: 24px;
        width: 100%;
        text-align: center;
    }
    .container{
        margin: 0px;
        padding: 20px;
        flex-direction: column;
        align-items: center;
    }
    .other_service_answer{
        position: static;
        width: 65%;
        margin: 5px 20px 20px 20px;
        transform: translateX(0%);
        font-size: 16px;
        padding: 20px;
        text-align: left;
        line-height: 24px;
    }
    .other_service_title{
        margin: 10px;
        font-size: 24px;
    }
    .other_service_desc{
        padding: 0px 35px;
        margin-bottom: 30px;
    }
    .other_service_title2{
        font-size: 24px;
        padding: 0 10px;
    }
    .other_service_desc2{
        background: #E1E9F5;
        margin-top: 30px;
        position: static;
    }
    .other_service_desc2 p{
        font-size: 20px;
        padding: 35px 25px 10px 25px;
        margin: 0px;
        line-height: 32px;
    }
    .other_service_desc2 img{
        width: 50%;
        position: static;
    }
    .other_services_section{
        padding: 35px 25px;
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 40px;
    }
    .other_services_section_options{
        padding: 20px 20px 30px 20px;
    }
    .other_services_section_desc{
        text-align: center;
        line-height: 32px;
    }
}
