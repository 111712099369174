.tab-container{
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    gap: 15px;
    background: #6C89B1;
    padding: 13px 20px;
    align-items: center;
    border-radius: 4px;
}
.tab-container > div{
    width: 150px;
    overflow: hidden;
    padding: 10px 40px;
    border-radius: 8px;
    cursor: pointer;
}
.tab-container .active{
    color: #6C89B1 !important;
    background: #ffffff;
    
}
.tab-container .tab{
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
}
.tab-content{
    width:80%;
    /* padding-left: 30px;
    padding-right: 30px; */
}
.plan-container{

}

.plan-name-heading {
    padding-right: 20px;
    flex: 0 1 400px;
}
.plan-header{
    /* display: flex; */
    flex-direction: row;
    gap: 60px;
    padding: 40px 0px 15px 0px;
}
.plan-header > div{
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 50px 10px 50px;
    border: 1px solid #6C89B147;
    border-radius: 4px;
    background: linear-gradient(0deg, rgba(108, 137, 177, 0.28), rgba(108, 137, 177, 0.28)),
linear-gradient(0deg, #E1E9F5, #E1E9F5);
}
.plan-header > div > div{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    color: #6C89B1;
}
.plan-grid{
    grid-template-columns: repeat(1, 1fr);
    display: grid;
    grid-column-gap: 60px;
    grid-row-gap: 30px;
}
.plan {
	border: 1px solid #E1E9F5;
	border-radius: 4px;
	padding: 15px;
	display: flex;
	/* gap: 20px; */
	justify-content: space-between;
	align-items: flex-start;
}
.plan h4{
    color: #1245A8;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    padding: 0;
}
.plan .content {
	display: flex;
	/* flex-direction: column; */
	/* gap: 5px; */
	/* height: -moz-fit-content; */
	/* height: fit-content; */
	justify-content: flex-start;
    align-items: flex-start;
    flex: 1 1 600px;
}
.plan .description{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    color: #18191FBF;
    margin: 0;
    text-align: left;
    padding-right: 20px;
    flex: 1
}
.plan .features{
    padding: 0;
    margin: 0;
    text-align: left;
    /*list-style-image: url('../Assets/right.svg');*/
}
.plan .features li{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #6C89B1;
    padding: 0;
    margin: 0;
    position: relative;
    list-style-type: none;
    margin-left: 30px;
}
.plan .features li::before{
    content: "";
    position: absolute;
    top: -5px;
    left: -30px;
    width: 25px;
    height: 25px;
    background-image: url('../Assets/right.svg');
}
.plan .action{
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 150px;
    flex: 0 0 auto;
}
.plan .price{
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0;
    margin: 0;
    height: fit-content;
    
}
.plan .inline{
    flex-direction: row;
    padding-bottom: 10px;
}
.plan .price .amount{
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #1245A8;
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
}
.plan .price .duration{
    padding: 0;
    margin: 0;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #18191FBF;
}
.plan .add_btn{
    border: 1px solid #1245A8;
    border-radius: 4px;
    padding: 0;
    margin: 0;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;
    color: #1245A8;
    background: none;
    cursor: pointer;
    padding: 7px 20px 7px 20px;
    white-space:nowrap;
    position: relative;
}

.pricing-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.price_section_titles{
    display: flex;
    list-style: none;
    gap: 100px;
    width: fit-content;
    background: #E1E9F5;
    padding: 8px 27px;
    color: #6C89B1;
    border-radius: 4px;
}
.option-box{
    padding: 10px;
    border: 1px solid #E1E9F5;
    border-radius: 4px;
    display: flex;
    gap: 0px;
    width: 423px;
    justify-content: space-between;
}
.option_name{
    font-size: 20px;
    color: #1245A8;
    width: 29%;
    text-align: left;
}
.option_description{
    width: 43%;
}
.option_price{
    width: 17%;
}
.pricing_title{
    font-size: 50px;
    color: #18191F;
    font-weight: 500;;
}

.selected_plans{
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
}
.selected_plans_container{
    display: flex;
    width: 100%;
    max-width: 1000px;
    margin: 0;
    padding: 0;
    gap: 20px;
}
.selected_plans_container > div{
    flex: 1;
}
.selected_plans_container .editable_list{
    display: flex;
    flex-direction: column;
}
.selected_plans_container .editable_list .header2{
    border: 1px solid #6C89B147;
    background: linear-gradient(0deg, rgba(108, 137, 177, 0.28), rgba(108, 137, 177, 0.28)),
linear-gradient(0deg, #E1E9F5, #E1E9F5);
    display: flex;
    border-radius: 4px;
    padding: 10px;
    text-align: left;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;
    color: #6C89B1;
}
.selected_plans_container .editable_list .header2 > :first-child{
    flex: 35;
}
.selected_plans_container .editable_list .header2 > :nth-child(2){
    flex: 40;
}
.selected_plans_container .editable_list .header2 > :nth-child(3){
    flex: 25;
    text-align: center;
}
.selected_editable_plan{

}
.selected_editable_plan > :first-child{

}
.selected_editable_plan > :nth-child(2){

}
.selected_editable_plan > :nth-child(3){

}
.selected_plans_container .final_list{
    background-color: red;
}
.pricing-content-section {
	display: flex;
	/* gap: 200px; */
	flex-grow: 1;
}
.add_btn.added {
    background-color: #1245A8;
    color: #fff;
  }

  .checked_icon{
    background: #ffffff;
    color: #1245A8;
    position: absolute;
    right: -5px;
    top: -5px;
    border-radius: 8px;
    border: 2px white;
  }
  
  .tick {
    display: inline-block;
    width: 16px;
    height: 16px;
    /* background-image: url("path/to/tick-icon.png"); */
    background-size: cover;
  }

@media (max-width:961px)  {
    .tab-container{
        width: 100%;
        gap: 10px;
        justify-content: space-around;
        padding: 0px;
        margin: 0px 20px !important;
    }
    .tab-container .tab{
        font-size: 14px;
        margin: 10px 5px;
    }
    .tab-container > div{
        width: 80px;
        padding: 8px 10px;
    }
    .pricing-content-section{
        display: flex;
        flex-direction: column;
        gap:20px;
    }
    .pricing-container{
        margin:10px 15px;
    }
    .tab-content{
        width:100%;
    }
    .plan-container{
        box-shadow: 0px 0px 11.399999618530273px 0px #00000040;
    margin: 20px 0px;
    }
    .plan-header{
        padding: 10px;
    }
    .plan-header > div{
        padding: 10px 30px 10px 30px;
        background: none;
        border: none;
    }
    .plan-grid{
        margin:0 15px;
    }
    .pricing-title-desc{
        display: none;
    }
    .plan .description{
        font-size: 12px;
        line-height: 15px;
    }
    .plan .features li{
        font-size: 12px;
    }
    .plan h4{
        font-size: 15px;
    }

    .content {
        flex: 1 1 0px !important
    }

    .plan-name-heading {
        flex: 1 1 0px !important
    }
}

