
.floating_cart {
	width: -moz-fit-content;
	width: fit-content;
	position: fixed;
	z-index: 999999;
	/* right: 0px; */
	/* top: 25%; */
	background: #1245A8;
	/* border-radius: 28px 0 0 28px; */
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 6px;
	gap: 10px;
	font-size: 14px;
	bottom: 20px;
	left: calc(50% - 250px);
	/* top: 15%; */
	width: 500px;
	height: 40px;
	border-radius: 16px;
	box-shadow: 0px 0px 11.4px 0px #00000070;
     font-size: 20px;
     font-weight: 600;
     line-height: 20px;
}

.floating_cart_text {
	/* margin: 0; */
	margin-left: 18px;
}

.floating_cart_view_link {
	margin-right: 18px;
     cursor: pointer;
}
.cart_icon{
     width: 8%;
}

.cart-container{
     display: flex;
     flex-direction: column
}

.cart-body{
     display: flex;
     flex-direction: column;
     flex: 1;
     overflow-y: auto;
     max-height: calc(100vh - 75px);
     color: var(--text-grey-color);
}

.cart-plan-container{
     display: flex;
     flex: 1
}



.cart-header-text {
	/* font-family: Inter; */
	font-size: 16px;
	font-weight: 600;
	line-height: 21px;
	/* text-align: center; */
	color: var(--text-color);
}

.cart-plan-container-div {
     flex: 1 1;
     display: flex;
     padding: 10px;

}

.cart-plan-container {
     padding: 10px 10px 0px 10px
}

.cart-price-header {
     font-family: Inter;
     font-size: 20px;
     font-weight: 600;
     line-height: 20px;
     margin-bottom: 16px;
}

.cart-price-info {
     margin: 0px 10px;
     padding: 0px 14px;
     display: flex;
     flex-direction: column;
 }

 .cart-price-header {

 }

 .bill-line-container {
     display: flex;
     justify-content: space-between;
     
 }

.noplan-container {
     display: flex;
     flex-direction: column;
     align-items: center;
}

.noplan-card-img {
     margin: 30% 0px 80px 0px;
}

.noplan-content {
     font-size: 14px;
     font-weight: 500;
     line-height: 20px;
}

.noplan-title {
     font-size: 20px;
     font-weight: 600;
     line-height: 20px;
     margin-bottom: 8px;
}

.cart-submit-button {
     min-height: 54px;
     display: flex;
     background-color: var(--text-color);
     justify-content: center;
     align-items: center;
     margin: 40px 24px;
     color: white;
     font-size: 20px;
     font-weight: 600;
     line-height: 20px;
     cursor: pointer;
     border-radius: 5px;
}


@media (max-width:961px)  {
   /* .floating_cart{
        top:10%;
        padding: 10px 0px;
   } */
   /* .floating_cart_text{
        display: none;
   } */
   .cart_icon{
        width:25%
   }
}

@media (max-width:480px)  {
     .floating_cart{
          bottom:10%;
          padding: 10px 0px;
          width: 300px !important;
          left: calc(50% - 150px);
     }
}