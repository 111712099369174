.homepage_section1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 150px 50px 150px;
    background: rgb(0,238,255);
background: linear-gradient(135deg, rgba(0,238,255,1) 0%, rgba(108,137,177,1) 59%);
    /* background: #D3DAF3; */
}
.section1_left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.section1_text1{
    font-size: 80px;
    margin: 0px;
    color: #191A15;
    font-weight: 700;
    text-align: left;
}
.section1_text2{
    margin: 10px 0px;
    font-size: 16px;
    color: #040404; 
}
.section1_button{
    padding: 15px 25px;
    background: #1245A8;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    font-size: 16px;
    margin-top:15px;
    cursor: pointer;
}
.section1_img{
    width: 500px;
}
.homepage_section2{
    display: flex;
    padding: 100px 150px;
    gap: 130px;
    justify-content: center;
    display: none;
}
.section2_img{
    width: 35%;
}
.section2_right{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}
.section2_text1{
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 20px;
}
.section2_text2{
    font-size: 16px;
    font-weight: 500;
    color: #717171;
}
.section2_text3{
    text-align: left;
    color: #717171;
    font-size: 16px;
    font-weight: 400;
    margin: 15px 0px 35px 0px;
}
.section2_button{
    padding: 15px 25px;
    background: #1245A8;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    font-size: 16px;
}
.homepage_section3{
    padding: 30px 250px;
}
.section3_text1{
    font-size: 50px;
    font-weight: 600;
    color: #191A15;
}
.section3_text2{
    font-size: 16px;
    font-weight: 400;
    color: #18191F;
    margin-bottom: 35px;
}    
.section3_options{
    background: #E1E9F5;
    border-radius: 8px;
    text-align: left;
    padding: 20px 50px;
    margin-bottom:30px;
}
.section3_option_title{
    margin: 0px;
    color: #191A15;
    font-size: 30px;
    font-weight: 600;
}
.section3_option_description{
    font-size: 18px;
    color: #191A15; 
}
.section3_option_button{
    color: #1245A8;
    font-size: 16px;
    font-weight: 600;
}
.section3_option_title_section{
    display: flex;
    align-items: center;
    gap: 10px;
}
.section3_option_title_section svg{
    background: #1245A8;
    color: #ffffff;
    padding: 8px;
    border-radius: 50px;
    font-size: 18px;
}
.section3_option_button{
    display: flex;
    align-items: center;
    gap: 10px;
    display: none;
}
.section3_option_button svg{
    font-size: 18px;
    margin-top:6px;
}
.section4_text1{
    font-size: 50px;
    font-weight: 600;
    color: #191A15;
}
.section4_text2{
    font-size: 16px;
    color: #717171;
    font-weight: 400;
}

.section4_option_title{
    font-size: 25px;
    color: #1245A8;
    font-weight: 500;
    padding: 0px 5px;
    position: absolute;
    top: -17px;
    left: 28px;
    background: white;
}
.section4_option_description{
    font-size: 24px;
    color: #4D4D4D;
}
.section4_option_button{
    font-size: 24px;
    color: #6C89B1;  
    display: none !important;  
}
.section4_options_box{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 30px 250px;
    grid-column-gap: 80px;
    grid-row-gap: 80px;
}
.section4_options{
    border: 1px solid #A9A9A9;
    border-radius: 16px;
    position: relative;
}
.section4_option_description{
    padding: 0px 30px;
    text-align: left;
    position: relative;
    font-size: 24px;
}
.homepage_section5{
    background: #E1E9F5;
}
.section5_text1{
    font-size: 50px;
    font-weight: 600;
    color: #191A15;
    padding-top: 32px;
}
.section5_text2{
    font-size: 16px;
    color: #717171;
    font-weight: 400;
    padding-top: 5px;
}
.section5_options_box{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
    padding: 50px 150px;
}
.section5_options{
    background: #ffffff;
    border-radius: 8px;
    padding: 25px;
}
.section5_option_title{
    font-size: 24px;
    color: #6C89B1;
    font-weight: 600;
    text-align: left;
}
.section5_option_description{
    font-size: 40px;
    color: #555555;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    padding: 30px 0px;
}
.section5_option_button{
    font-size: 20px;
    color: #555555;
    font-weight: 400;
    text-align: left;
}
.section5_option_description2{
    font-size:20px;
    line-height: 16px;
}
@media (max-width:961px)  {
    .homepage_section1{
        flex-direction: column;
        padding: 10px 10px 30px 10px;
    }
    .section1_left{
        text-align: left;
        padding: 10px 10px 20px 10px;
    }
    .section1_text1{
        font-size: 36px;
    }
    .section1_button{
        margin-top: 5px;
        font-size: 12px;
        padding: 10px 20px;
        cursor: pointer;
    }
    .section1_img{
        width:55%;
    }
    .homepage_section2{
        flex-direction: column-reverse;
        padding: 20px;
        gap: 0px;
    }
    .section2_img{
        width:93%;
    }
    .section2_right{
        align-items: center;
    }
    .section2_text1{
        font-size: 24px;
        margin-bottom: 10px;
    }
    .section2_text3{
        text-align: justify;
    }
    .section2_button{
        display: none;
    }
    .homepage_section3{
        padding: 25px;
    }
    .section3_text1{
        font-size: 24px;
    }
    .section3_text2{
        margin-bottom: 10px;
        padding: 10px;
    }
    .section3_options{
        padding: 20px;
        margin-bottom: 20px;
    }
    .section3_option_title{
        font-size: 18px;
    }
    .section3_option_description{
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .section3_option_title_section svg{
        background: #1245A8;
        color: #ffffff;
        padding: 7px;
        border-radius: 50px;
        font-size: 12px;
    }
    .section3_option_button svg{
        font-size: 12px;
    }
    .homepage_section4{
        /* display: none; */
    }
    .section4_text1{
        font-size: 24px;
    }
    .section4_options_box{
        padding: 20px 50px;
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 20px;
    }
    .section4_option_title{
        font-size: 20px;
    }
    .section4_option_description{
        font-size: 16px;
        padding: 0px 15px;
    }
    .section4_option_button{
        font-size: 16px;
        align-items: flex-end;
        display: flex;
        padding: 0px 15px 20px 15px;
    }
    .homepage_section5{
        background: none;
        padding: 15px 0px;
    }
    .section5_text1{
        font-size: 24px;
    }
    .section5_text2{
        padding: 10px 45px;
    }
    .section5_options_box{
        padding: 20px 40px;
        grid-row-gap: 20px;
        grid-template-columns: repeat(1, 1fr);
    }
    .section5_options{
        box-shadow: 0px 0px 24px 0px #00000040;
    }
    .section5_option_title{
        font-size: 16px;
    }
    .section5_option_description{
        font-size: 36px;
    }
    .section5_option_description2{
        font-size: 16px;
        line-height: 14px;
    }
    .section5_option_button{
        font-size: 16px;
        line-height: 24px;
    }
}