.tax_options_list{
   display: flex; 
   flex-wrap: wrap;
   gap: 20px;
   padding: 30px 150px;
   justify-content: center;
}


.tax_options_list .option{
    display: flex;
    width: 240px;
    border: 1px solid #BCD3FF;
    background-color: #FFFFFF;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    gap: 30px;
    color: #1245A8;
    font-size: 16px;
    line-height: 17px;
    font-weight: 500;
    position: relative;
    cursor: pointer;
    align-items: center;
    height:50px;
}
.tax_options_list .option .content{
    flex: 1;
    text-align: left;
    height: fit-content;
}
.tax_options_list .option .icon{
    width: 40px;
}
.tax_options_list .option .checkbox{
    width: 20px;
    height: 20px;
    background-color: #FFF;
    border-radius: 50%;
    border: 1px solid #BCD3FF;
    position: absolute;
    top: -10px;
    left: -10px;
    cursor: pointer;
}
.tax_options_list .option .check_icon{
    color: #1245A8;
    font-size: 10px;
    display: none;
}
.tax_options_list .option.checked{
    background-color: #1245A8;
    color: #FFFFFF;
}
.tax_options_list .option.checked .icon{
    filter: brightness(0) invert(1);
}
.tax_options_list .option.checked{
    background-color: #1245A8;
    color: #FFFFFF;
}
.tax_options_list .option.checked .check_icon{
    display: inline-block;
}
.documents_button{
    background: #1245A8;
    color: #ffffff;
    border: none;
    padding: 14px 32px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}
.document_list_section{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.documents_list{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    padding-left: 25px;
    padding-right: 25px;
    width: fit-content;
}
.documents_list > div{
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    display: flex;
    gap: 10px;
}
.documents_list .icon{
    color: #1245A8;
    margin-top: 7px;
}
.documents_lis .name{
    color: #4D4D4D;
}

.talk-to-us-button-container {
    margin: 30px 0px
}
@media (max-width:750px)  {
    .documents_list{
        grid-template-columns: repeat(1, 1fr);
    }

    .tax_options_list{
        display: flex; 
        flex-wrap: wrap;
        gap: 20px;
        padding: 30px;
        justify-content: center;
     }
}

@media (min-width:750px)  {
    .tax_options_list .option {
        width: 220px;
    }
}