

.slide-cart {
    position: fixed;
    top: 0;
    right: -300px; 
    width: 300px;
    height: 100vh;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease-in-out;
    z-index: 999;
  }
  
  .slide-cart.open {
    right: 0; 
  }
  
  .cart-header {
    padding: 10px;
    background-color: var(--secondary-bgcolor);
    border-bottom: 1px solid #ddd;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative
  }

  .close-cart-icon {
    position: absolute;
    right: 15px;
    cursor: pointer;
}
  
  .cart-header h2 {
    margin: 0;
    font-size: 18px;
  }
  
  .cart-items {
    padding: 10px;
    overflow-y: auto;
  }
  
  .cart-item {
    margin-bottom: 10px;
  }
  
  .cart-item span {
    margin-right: 10px;
  }
  
  .cart-item button {
    background-color: #ff4d4f;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
