.modal-form-container {
    padding: 23px;
    background: url('../Assets/Background.png');
    background-size: cover;
}

.modal-form-card {
    background-color: white;
    border: 1px solid var(--secondary-text-color);
    padding: 32px 32px 20px 32px;

}

.modal-form-header {
    padding-bottom: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 25.1px;
    color: var(--secondary-text-color);
}

.modal-form-body {
    display: flex;
    flex-direction: column;

}

.modal-form-close-button {
    cursor: pointer;
}

.modal-form-group {
    padding: 17px 0px;
    display: flex;
    justify-content: space-between;
}

.modal-form-control-container {
    display: flex;
    position: relative;
    flex: 1
}

.modal-form-control {
    flex: 1;
    padding: 14px;
    border-radius: 5px;
    border: 1px solid var(--secondary-text-color);
    background-color: var(--secondary-bgcolor);
}

.error-message-text {
    position: absolute;
    bottom: -18px;
    font-size: 12px;
    left: 5px;
    color: maroon;
}

.first-name {
    margin-right: 15px;
}

.footnote {
    color: var(--secondary-text-color);
    text-align: center;
}

.last-name {
    margin-left: 15px;
}

.PhoneInputInput {
    padding: 15px 0px;
    border: 0px solid;
    background-color: var(--secondary-bgcolor);
    
}

.PhoneInputCountry {
    padding: 14px;
}

.modal-form-submit {
    flex: 1;
    height: 64px;
    background-color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.modal-form-redirection-icon-container {
    margin-left: 10px;
}

.breakdiv {
    min-width: 30px;
}

.disabled {
    background-color: var(--secondary-text-color) !important;
    opacity: 0.5;
    cursor: not-allowed;
}
